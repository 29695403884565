import React,{ useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import aircraftApi from "../../api/aircraft.api";
import airportApi from "../../api/airport.api";
import coveragesApi from "../../api/coverages.api";
import pilotApi from "../../api/pilot.api";
import {UserApi} from "../../api/user.api";
import { AlertContextStore } from "../AlertContext/AlertContext";
import {LoadingContextStore} from "../LoadingContext/LoadingContext"
import { SessionContextStore } from "../SessionContext/SessionContext";
import paymentApi from "../../api/payment.api";
import StorageService from "../../services/StorageService";
import { QuoteApi } from '../../api/quote.api';
import { reportGTM } from "../../components/Core/Utilities/GTagManger";
import { QUOTE_STATUS } from "./types";
import summaryApi from "../../api/summary.api";


const QUOTE_ACTIONS = {
    SET_STEP: "SET_STEP",
    LOAD_STATE: "LOAD_STATE",
    LOAD_AVAILABLE_MEMBERSHIPS:"LOAD_AVAILABLE_MEMBERSHIPS",
    REFRESH_MEMBERSHIPS:"REFRESH_MEMBERSHIPS",
    FINISH_PROFILE: "FINISH_PROFILE",
    LOAD_AIRCRAFT: "LOAD_AIRCRAFT",
    FINISH_AIRCRAFTS: "FINISH_AIRCRAFTS",
    LOAD_AIRPORT: "LOAD_AIRPORT",
    FINISH_AIRPORTS: "FINISH_AIRPORTS",
    ADD_PILOT: "ADD_PILOT",
    DELETE_PILOT: "DELETE_PILOT",
    SET_CURRENT_PILOT: "SET_CURRENT_PILOT",
    EDIT_PILOT: "EDIT_PILOT",
    DELETE_PILOT: "DELETE_PILOT",
    FINISH_PILOTS: "FINISH_PILOTS",
    FINISH_COVERAGES: "FINISH_COVERAGES",
    LOAD_OPTIONS: "LOAD_OPTIONS",
    LIABILITY_COVERAGES_CHANGED: "LIABILITY_COVERAGES_CHANGED",
    HULL_COVERAGES_CHANGED: "HULL_COVERAGES_CHANGED",
    MEDICAL_COVERAGES_CHANGED: "MEDICAL_COVERAGES_CHANGED",
    PRICE_BREAKDOWN_CHANGED: "PRICE_BREAKDOWN_CHANGED",
    FINISH_UNKNOWN_NNUMBER_POPUP:"FINISH_UNKNOWN_NNUMBER_POPUP",
    GO_BACK: "GO_BACK",
    CHANGE_STARTING_DATE: "CHANGE_STARTING_DATE",
    CLIENT_SECRET: "CLIENT_SECRET",
    FINISH_SUMMARY: "FINISH_SUMMARY",
    BIND: "BIND",
    LOAD_QUOTE: "LOAD_QUOTE",
    CHANGE_AIRPORT_MODE: "CHANGE_AIRPORT_MODE",
    SET_LOGGED_USER: "SET_LOGGED_USER",
    RESET_CURRENT_PILOT: "RESET_CURRENT_PILOT",
    UPDATE_INSTALLMENTS_TYPE: "UPDATE_INSTALLMENTS_TYPE",
    LIABILITY_QUOTE: "LIABILITY_QUOTE"
}

export const generateNewAirport = () => {
    return {
        code: "",
        name: "",
        city: "",
        state: "default",
        runway_length: "",
        runway_elevation: "",
        runway_surface: "default"
    }
}

export const STAGES = {
    PROFILE: "Profile",
    AIRCRAFTS: "Aircrafts",
    AIRPORTS: "Airports",
    PILOTS: "Pilots",
    COVERAGES: "Coverages",
    SUMMARY: "Summary",
    CHECKOUT: "Checkout",
    CONGRATULATIONS: "Congratulations",
    DECLINE: "Decline",
    UNDERWRITER: "Underwriter",
    NOTCLEARED: "NotCleared",

}

export const steps = {
    [STAGES.PROFILE]: { url: '/insurance/profile', step: 0 },
    [STAGES.AIRCRAFTS]:{ url: '/insurance/aircraft', step: 1 },
    [STAGES.AIRPORTS]:{ url: '/insurance/airport', step: 1 },
    // [STAGES.PILOTS]:{ url: '/insurance/pilots', step: 2 },
    [STAGES.PILOTS]:{ url: '/insurance/pilot-list', step: 2 },
    [STAGES.COVERAGES]:{ url: '/insurance/coverages', step: 3 },
    [STAGES.SUMMARY]:{ url: '/insurance/summary', step: 4 },
    [STAGES.CHECKOUT]:{ url: '/insurance/checkout', step: 4 },
    [STAGES.UNDERWRITER]:{ url: '/insurance/underwritterrefferal', step: 4 },
    [STAGES.NOTCLEARED]:{ url: '/insurance/notcleared', step: 4 },
    [STAGES.DECLINE]:{ url: '/insurance/declined', step: 4 },
    [STAGES.CONGRATULATIONS]:{ url: '/insurance/congratulations', step: 4 },
};

export const PAYMENT_STATUS = {
    SUCCESS: "Succeeded",
    FAILED: "Failed",
    PENDING: "Pending",
    CREATED: "Created"
}

export const AIRCRAFT_CLASS = {
    ME: "ME",
    MX: "MX",
    RG: "RG",
    CG: "CG"
}

export const USER_REGISTRATION_STATUS = {
    SUCCESS: "Succeeded",
    FAILED_REGISTRATION: "Failed on registeration",
    FAILED_SAVE_PROFILE: "Failed on saving profile"
}

export const useActions = (dispatch, state) => {
    const location = useLocation();
    const history = useHistory();
    const { register, isLoggedIn, setQuote, user, impersonationDetails } = useContext(SessionContextStore)
    const { awaitWithLoading, setLoading } = useContext(LoadingContextStore);
    const { showServerError } = useContext(AlertContextStore);
    const { url } = useRouteMatch();


    let userEmail = impersonationDetails?.email ? impersonationDetails?.email : user?.email

    return {
        setStep: async function() {
            const step = location.pathname.split('/')[2];
            switch (step) {
                case 'profile':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 0, title: "profile", shouldShowPriceWidgit: false}
                    })
                    reportGTM({'event': 'owners-portal-loaded', at: window.location.pathname})
                    break;
                case 'aircraft':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 1, title: "aircraft", shouldShowPriceWidgit: false}
                    })
                    break;
                case 'airport':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 1, title: "airport", shouldShowPriceWidgit: false}
                    })
                    break;
                case 'pilots':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 2, title: "pilot", shouldShowPriceWidgit: false}
                    })
                    break;
                case 'pilot-list':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 2, title: "pilot", shouldShowPriceWidgit: false}
                    })
                    break;
                case 'coverages':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 3, title: "coverages", shouldShowPriceWidgit: false}
                    })
                    break;
                case 'summary':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 4, title: "summary", shouldShowPriceWidgit: false}
                    })
                    break;
                case 'checkout':
                    dispatch({
                        type: QUOTE_ACTIONS.SET_STEP,
                        payload: { step: 4, title: "checkout", shouldShowPriceWidgit: true}
                    })
                    break;
                default:
                    break;
            }
        },
        loadMemberships:async function(){
            const { data, ok} = await UserApi.loadAvilabileMemberships();
            dispatch({
                type: QUOTE_ACTIONS.LOAD_AVAILABLE_MEMBERSHIPS,
                payload: {data}
            })
        },
        addMembership: async function(data){
            dispatch({
                type: QUOTE_ACTIONS.REFRESH_MEMBERSHIPS,
                payload: {data}
            })
        },
        deleteLiabilityQuote: async function(liabilityQuoteId) {
            await QuoteApi.deleteQuote(liabilityQuoteId);
            dispatch({
                type: QUOTE_ACTIONS.LIABILITY_QUOTE,
                payload: {liabilityQuoteId: null, status: "Application"}
            }) 
        },
        setInstallmentsType: async function(data){
            dispatch({
                type: QUOTE_ACTIONS.UPDATE_INSTALLMENTS_TYPE,
                payload: data
            })
        },
        removeMembership: async function(membershipToRemove,membershipList){
            var data = membershipList.filter(x=>x != membershipToRemove)
            dispatch({
                type: QUOTE_ACTIONS.REFRESH_MEMBERSHIPS,
                payload: {data}
            })
        },
        finishProfile: async function(user) {
            if(!isLoggedIn) {
                const registerStatus = await register(user);
                if(!registerStatus) {
                    return USER_REGISTRATION_STATUS.FAILED_REGISTRATION;
                }
                user.is_new_registration = true;
            }
            const { data: savedUserState, ok: saveStatus } = await UserApi.saveProfile(user);
            if(!saveStatus) {
                showServerError({title: savedUserState.title});
                return USER_REGISTRATION_STATUS.FAILED_SAVE_PROFILE;
            }
            await setQuote(savedUserState.quote_id);
            savedUserState['n_number'] = user.n_number
            savedUserState.profile['add_later'] = savedUserState.add_n_number_later
            savedUserState.profile['bypass_clearance'] = savedUserState.bypass_clearance
            Object.keys(savedUserState.aircrafts).map(key=>{
                savedUserState.aircrafts[key]['is_hangared'] = savedUserState.aircrafts[key]['is_hangared'].toString()
            })
            dispatch({
                type: QUOTE_ACTIONS.FINISH_PROFILE,
                payload: { ...savedUserState, currentStep: 1}
            })
            const quoteId = savedUserState.quote_id;
            history.push({
                pathname: `${url}/aircraft/${quoteId}`,
                state: { quoteId }
              }); 
            reportGTM({'event': 'continue-button-at-profile', at: window.location.pathname})
            return USER_REGISTRATION_STATUS.SUCCESS;
        },
        loadAircraft: async function(aircraft) {
            dispatch({
                type: QUOTE_ACTIONS.LOAD_AIRCRAFT,
                payload: { ...aircraft, currentStep: 1}
            })
            reportGTM({'event': 'profile-saved', userEmail, at: window.location.pathname})
            reportGTM({'event': 'aircraft-page-loaded', userEmail, at: window.location.pathname})
        },
        finishAircraft: async function(aircrafts) {
            const { data, ok } = await awaitWithLoading(await aircraftApi.saveAircraftPage(aircrafts));
            if(!ok) {
                showServerError({message: "Error has occured"});
                return;
            }
            Object.keys(data.aircrafts).map(key=>{
                data.aircrafts[key]['is_hangared'] = data.aircrafts[key]['is_hangared'].toString()
            })
            const { aircrafts: aircraftData } = data;
            dispatch({
                type: QUOTE_ACTIONS.FINISH_AIRCRAFTS,
                payload: { ...data, aircrafts: {[Object.keys(aircraftData)[0]]: aircraftData[Object.keys(aircraftData)[0]]}, currentStep: 1}
            })
            reportGTM({'event': 'aircraft-details-saved', userEmail, at: window.location.pathname})
            history.push(`/insurance/airport/${aircrafts.quote_id}`);
            reportGTM({'event': 'airport-page-loaded', userEmail, at: window.location.pathname})
        },
        loadAirport: async function(airport) {
            const formattedAirport = {
                name: airport.name,
                state: airport.state,
                city: airport.city,
                runway_length: airport.runway_length,
                runway_elevation: airport.runway_elevation,
                code: !airport.airport_not_found ? airport.code : "",
                runway_surface: airport.runway_surface,
                airport_not_found: airport.airport_not_found
            }
            dispatch({
                type: QUOTE_ACTIONS.LOAD_AIRPORT,
                payload: { ...formattedAirport, currentStep: 2}
            })
        },
        changeAirportMode: async function(mode) {
            dispatch({
                type: QUOTE_ACTIONS.CHANGE_AIRPORT_MODE,
                payload: mode
            })
        },
        finishAirport: async function(airport) {
            airport.code = !airport.airport_not_found ? airport.code : null;
            const { data, ok } = await airportApi.saveAirportPage(airport);
            if(!ok) {
                showServerError({message: "Error has occured"});
                return;
            }
            Object.keys(data.aircrafts).map(key=>{
                data.aircrafts[key]['is_hangared'] = data.aircrafts[key]['is_hangared'].toString();
            })
            const { airports: airportData } = data;
            dispatch({
                type: QUOTE_ACTIONS.FINISH_AIRPORTS,
                payload: { ...data, airports: {[Object.keys(airportData)[0]]: airportData[Object.keys(airportData)[0]]}, currentStep: 2}
            })
            reportGTM({'event': 'airport-details-saved', userEmail, at: window.location.pathname})
            const { pilots } = state;
            if(!pilots) {
                history.push(url + `/pilots/${data.quote_id}`);
                reportGTM({'event': 'pilot-page-loaded', userEmail, at: window.location.pathname})
            }else {
                history.push(url + `/pilot-list/${data.quote_id}`);
            }
        },

        addPilot: async function(pilotToSave) {
            const { data, ok } = await awaitWithLoading(pilotApi.savePilot(pilotToSave));
            dispatch({
                type: QUOTE_ACTIONS.ADD_PILOT,
                payload: { ...data }
            })
            history.push(url + `/pilot-list/${pilotToSave.quote_id}`);
        },

        deletePilot: async function(pilotId, quoteId) {
            const { data, ok } = await awaitWithLoading(pilotApi.deletePilot(pilotId, quoteId));
            if(!ok) {
                showServerError({message: "Error has occured"});
                return;
            }
            dispatch({
                type: QUOTE_ACTIONS.DELETE_PILOT,
                payload: { pilotId }
            })
            history.push(url + `/pilot-list/${quoteId}`);
        },

        setCurrentPilot: async function(pilotId, quoteId) {
            dispatch({
                type: QUOTE_ACTIONS.SET_CURRENT_PILOT,
                payload: pilotId
            })
            history.push(url + `/pilots/${quoteId}`);
        },
        resetCurrentPilot: async function(quoteId) {
            dispatch({
                type: QUOTE_ACTIONS.RESET_CURRENT_PILOT
            })
            history.push(url + `/pilots/${quoteId}`);
        },

        editPilot: async function(pilot,nextPilotId) {
            const { data, ok } = await awaitWithLoading(pilotApi.updatePilot(pilot));
            if(!ok) {
                showServerError({message: "Error has occured"});
                return;
            }
            
            dispatch({
                type: QUOTE_ACTIONS.EDIT_PILOT,
                payload: { pilot ,nextPilotId}
            })
            history.push(url + `/pilot-list/${pilot.quote_id}`);
        },

        finishPilots: async function(pilots, shouldSave) {
            if (!pilots.quote_id) return;
            
            let payload = { currentStep: 3 };
            if(shouldSave) {
                const { data, ok } = await awaitWithLoading(pilotApi.savePilot(pilots));
                if(!ok) {
                    showServerError({message: "err"});
                    return;
                }
                payload = { ...payload, ...data}
            }
            dispatch({
                type: QUOTE_ACTIONS.FINISH_PILOTS,
                payload
            })
            reportGTM({'event': 'pilot-details-saved', userEmail, at: window.location.pathname})
            history.push(url + `/coverages/${pilots.quote_id}`);
            reportGTM({'event': 'customize-page-loaded', userEmail, at: window.location.pathname})
        },
        finishCoverages: async function(selected) {
            const { physical_damage_coverages, liability_coverages,medical_coverages } = state.coverages.options;
            
            const coverages = {
                ...selected,
                liability_medical_coverage: medical_coverages[Object.keys(medical_coverages)[0]],
                hull_coverage: physical_damage_coverages[selected.physical_damage_coverage],
                liability_coverage: liability_coverages[selected.liability_coverage]
            }
            const { ok: saveCoveragesStatus } = await awaitWithLoading(coveragesApi.saveCoverages(coverages));
            if(!saveCoveragesStatus) {
                showServerError({message: "Failed saving coverages"});
                return;
            }
            const result = await awaitWithLoading(coveragesApi.requestQuote(coverages));
            if(!result.ok) 
            {
                reportGTM({'event': 'api-failure-page-loaded', userEmail, at: '/insurance/wearealmostthere'})
                history.push(`/insurance/wearealmostthere/${selected.quote_id}`);
                return;
            }
  
            const { status } = result.data.current_application;
            dispatch({
                type: QUOTE_ACTIONS.FINISH_COVERAGES,
                payload: { ...result, currentStep: 4, status}
            })
            switch(status)
            {
                case QUOTE_STATUS.UNDERWRITER:
                    if(result.data.new_application !== undefined ){
                        dispatch({
                            type: QUOTE_ACTIONS.LIABILITY_QUOTE,
                            payload: {liabilityQuoteId : result.data.new_application.quote_id, status:result.data.new_application.status }
                        })
                    }
                    reportGTM({'event': 'referral-page-loaded', userEmail, at: '/insurance/underwritterrefferal'})
                    history.push(`/insurance/underwritterrefferal/${selected.quote_id}`);
                    return;
                case QUOTE_STATUS.NOTCLEARED:
                    reportGTM({'event': 'not-cleared', userEmail, at: '/insurance/notcleared'})
                    history.push(`/insurance/notcleared/${selected.quote_id}`);
                    return;
                case QUOTE_STATUS.DECLINED:
                    if(result.data.new_application !== undefined){
                        dispatch({
                            type: QUOTE_ACTIONS.LIABILITY_QUOTE,
                            payload: {liabilityQuoteId : result.data.new_application.quote_id, status:result.data.new_application.status }
                        })
                    }
                    reportGTM({'event': 'declined-page-loaded', userEmail, at: '/insurance/declined'})
                    history.push(`/insurance/declined/${selected.quote_id}`);
                    return;
                case QUOTE_STATUS.APPLICATION:
                    reportGTM({'event': 'api-failure-page-loaded', userEmail, at: '/insurance/wearealmostthere'})
                    history.push(`/insurance/wearealmostthere/${selected.quote_id}`);
                    return;
                case QUOTE_STATUS.QUOTED:
                    reportGTM({'event': 'coverages-saved', userEmail, at: window.location.pathname})
                    history.push(url + `/summary/${selected.quote_id}`);
                    reportGTM({'event': 'summary-page-loaded', userEmail, at: window.location.pathname})
                    return;
                default:
                    return;
       
            }
        },
        changeLiabilityCoverage: async function(key, option) {
            let shouldShow = true
            if(option.passenger_limit === 0){
                shouldShow = false
            }
            dispatch({
                type: QUOTE_ACTIONS.LIABILITY_COVERAGES_CHANGED,
                payload: { option, key ,shouldShowMedical : shouldShow}
            })
        },
        changeMedicalCoverage: async function(key, option){
            dispatch({
                type: QUOTE_ACTIONS.MEDICAL_COVERAGES_CHANGED,
                payload: { option }
            })
        },

        changeHullCoverage: async function(key, option){
            dispatch({
                type: QUOTE_ACTIONS.HULL_COVERAGES_CHANGED,
                payload: { option, key }
            })
        },

        getClearance: async function(quoteId){
            const res = await awaitWithLoading(coveragesApi.getClearance(quoteId));
            if(!res.ok) {
                history.push(`/insurance/notcleared/${quoteId}`);
                return;
            }
            return res;
        },

        loadOptions: async function(quoteId) {
            const res = await awaitWithLoading(coveragesApi.loadOptions(quoteId));
            if(!res.ok) {
                reportGTM({'event': 'api-failure-page-loaded', userEmail, at: window.location.pathname})
                history.push(`/insurance/wearealmostthere/${quoteId}`);
                return;
            }
            const { is_referred } = res.data.options;
            const {selected} = state.coverages;
            const {physical_damage_coverages, liability_coverages} = res.data.options;
            dispatch({
                type: QUOTE_ACTIONS.LOAD_OPTIONS,
                payload: { 
                    coverages: res.data,
                    shouldResetLiability: !liability_coverages[selected.liability_coverage] && selected.liability_coverage !== "default",
                    shouldResetHull: !physical_damage_coverages[selected.physical_damage_coverage] && selected.physical_damage_coverage !== "default",
                    shouldShowPriceWidgit: !is_referred,
                    status: QUOTE_STATUS.APPLICATION
                }
            })
        },
        changeStartDate: async function (date){
            dispatch({
                type: QUOTE_ACTIONS.CHANGE_STARTING_DATE, 
                payload: date
            })
        },
        loadPriceBreakdown: async function(selected){
            const { options } = state.coverages;
            if(!options) return;
            const { physical_damage_coverages, liability_coverages } = options;
            let prices ={
                physical_damage_coverage: selected.physical_damage_coverage === 'default' ||  selected.physical_damage_coverage === undefined ? 0 : physical_damage_coverages[selected.physical_damage_coverage].premium,
                liability_coverage: selected.liability_coverage === 'default' ? 0 : liability_coverages[selected.liability_coverage].premium,
                medical_coverage: 0
            }
            dispatch({
                type: QUOTE_ACTIONS.PRICE_BREAKDOWN_CHANGED,
                payload: prices
            })
        },
        finishSummary: async function(data) {
            const { ok } = await awaitWithLoading(summaryApi.saveSummary(data.quoteId, data));
            if(!ok){
                showServerError({message: "An error has occured"});
                return;
            }
            dispatch({
                type: QUOTE_ACTIONS.FINISH_SUMMARY,
                payload: data
            })
            history.push(url + `/checkout/${data.quoteId}`);
            reportGTM({'event': 'payment-page-loaded', userEmail, at: window.location.pathname})
        },
        finishUnknownNNumberPopup: async function(quote_id, aircraft_id, n_number, selected_coverages, start_date){
            const res = await awaitWithLoading(aircraftApi.saveNNumberToExistingAircraft({ quote_id, aircraft_id, n_number }));
            
            return res;
            
            //const result = await awaitWithLoading(coveragesApi.requestQuote({...selected_coverages, quote_id: quote_id, start_date: start_date}));

            // const { status } = result.data;
            // switch(status)
            // {
            //     case QUOTE_STATUS.UNDERWRITER:
            //     case QUOTE_STATUS.DECLINED:
            //         reportGTM({'event': 'referral-page-loaded', userEmail, at: '/insurance/underwritterrefferal'})
            //         history.replace('/insurance/underwritterrefferal');
            //         return false;
            //     case QUOTE_STATUS.APPLICATION:
            //         reportGTM({'event': 'api-failure-page-loaded', userEmail, at: '/insurance/wearealmostthere'})
            //         history.replace('/insurance/wearealmostthere');
            //         return false;
            //     case QUOTE_STATUS.QUOTED:
            //         dispatch({
            //             type: QUOTE_ACTIONS.FINISH_UNKNOWN_NNUMBER_POPUP,
            //             payload: data
            //         })
            //         reportGTM({'event': 'N-number-pop-up', userEmail, at: window.location.pathname})
            //         return true;
            // }
        },
        finishCheckout: async function(quoteId, paymentType) {
            setLoading(true);
            const { ok: preBindSuccess, data } = await paymentApi.preBind(quoteId, paymentType);
            if(preBindSuccess) {
                const { transaction_id, should_check_status } = data;
                if (should_check_status) {
                    const checkStatus = async () => {
                        const { data } = await paymentApi.checkChargeStatus(transaction_id, quoteId); 
                        switch(data.status) {
                            case PAYMENT_STATUS.SUCCESS:
                                setLoading(false);
                                dispatch({
                                    type: QUOTE_ACTIONS.BIND,
                                })
                                history.replace(url + `/congratulations/${quoteId}`);
                                reportGTM({'event': 'congratulations-page-loaded', userEmail, value: state.price, at: window.location.pathname})
                                setQuote();
                                break;
                            case PAYMENT_STATUS.PENDING:
                            case PAYMENT_STATUS.CREATED:
                                setTimeout(checkStatus, 100)
                                break;
                            case PAYMENT_STATUS.FAILED:
                                showServerError({title:"We couldn’t process your payment, please try another payment method.",message: "Payment failed"});
                                setLoading(false);
                                break;
                            default:
                                showServerError({title:"We couldn’t process your payment, please try another payment method.",message: "Payment failed"});
                                setLoading(false);
                                break;
                        }
                    }
                    setTimeout(async () => {
                        await checkStatus();
                    }, 100)
                    reportGTM({'event': 'payment-saved', userEmail, value: state.price, at: window.location.pathname})
                } else {
                    await paymentApi.changeStageFuturePolicy(quoteId); 
                    setLoading(false);
                    dispatch({
                        type: QUOTE_ACTIONS.BIND,
                    })
                    history.replace(url + `/congratulations/${quoteId}`);
                    reportGTM({'event': 'congratulations-page-loaded', userEmail, value: state.price, at: window.location.pathname})
                    setQuote();
                }
            } else {
                setLoading(false);
                showServerError({message: "Error has occured"});
                return;
            }
        },
        getClientSecret: async function() {
            const secret = StorageService.getItem("clientSecret");
            if(secret) return;
            const {data, ok} = await paymentApi.getClientSecret();
            if(!ok) return;
            StorageService.setItem("clientSecret", data);
            dispatch({
                type: QUOTE_ACTIONS.CLIENT_SECRET,
                payload: data
            })
        },

        updateRenewalQuote: async function(quoteId){
            const {data,ok} = await awaitWithLoading(QuoteApi.updateRenewalQuote(quoteId))
            switch(data.status)
            {
                case QUOTE_STATUS.QUOTED:
                    history.push(`/insurance/savechangesrenewal/${quoteId}`);
                    return;
                default:
                    history.push(`/insurance/underwritterrefferal/${quoteId}`);
                    return;
            }
        },
        loadQuote: async function(quoteId, shouldRedirect) {
            if(!isLoggedIn) return;

            if(!quoteId) return;
            await setQuote(quoteId);
            const { data, ok } =  await awaitWithLoading(QuoteApi.getQuote(quoteId));
            if(!ok) {
                showServerError({message: "err"});
                return;
            }
            
            Object.keys(data.aircrafts).map(key=>{
                data.aircrafts[key]['is_hangared'] = data.aircrafts[key]['is_hangared'].toString()
            })
            data.shouldShowMedicalCoverage = !(data.coverages && data.coverages.selected && data.coverages.selected.liability_coverage.includes("-0"));
            data.profile['add_later'] = data.add_n_number_later;
            data.profile['bypass_clearance'] = data.bypass_clearance;
            data.airportSearchMode = Object.keys(data.airports).some(key => data.airports[key].airport_not_found) ? 'details' : 'code'
            dispatch({
                type: QUOTE_ACTIONS.LOAD_QUOTE,
                payload: data
            })
            if(data.pilots && Object.keys(data.pilots).length > 0){
                dispatch({
                    type: QUOTE_ACTIONS.SET_CURRENT_PILOT,
                    payload: Object.keys(data.pilots)[0]
                })
            }
            if(!shouldRedirect) return ok;
            
            const { stage } = data;
            if(!stage) {
                history.replace(steps[STAGES.PROFILE].url + `/${quoteId}`);
                return ok;
            };
            return ok

            //history.replace(steps[stage].url + '/' + quoteId);
        },
        setQuoteUser: async function(email) {
            if(!isLoggedIn) return;
            
            dispatch({
                type: QUOTE_ACTIONS.SET_LOGGED_USER,
                payload: email
            });
        },
        goBackBrowser: async function(location, quoteId) {
            const url = location;
            switch (url) {
                case `/insurance/profile/${quoteId}`:
                    await awaitWithLoading(aircraftApi.goBack(quoteId));
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 0, title: "profile", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/aircraft/${quoteId}`:
                    await awaitWithLoading(airportApi.goBack(quoteId));
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 1, title: "aircraft", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/airport/${quoteId}`:
                    await awaitWithLoading(pilotApi.goBack(quoteId));
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 1, title: "airport", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/pilot-list/${quoteId}`:
                    await awaitWithLoading(coveragesApi.goBack(quoteId));
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 2, title: "pilot", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/pilots/${quoteId}`:
                    await awaitWithLoading(coveragesApi.goBack(quoteId));
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 2, title: "pilot", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/coverages/${quoteId}`:
                    await awaitWithLoading(summaryApi.goBack(quoteId));
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 3, title: "coverages", shouldShowPriceWidgit: true}
                    })
                    break;
                case `/insurance/summary/${quoteId}`:
                    await awaitWithLoading(paymentApi.goBack(quoteId));
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 4, title: "summary", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/checkout/${quoteId}`:
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 5, title: "checkout", shouldShowPriceWidgit: true}
                    })
                    break;
                default:
                    break;
            }
        },
        goBack: async function(quoteId) {
            const url = location.pathname;
            switch (url) {
                case `/insurance/aircraft/${quoteId}`:
                    await awaitWithLoading(aircraftApi.goBack(quoteId));
                    history.push(`/insurance/profile/${quoteId}`)
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 0, title: "profile", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/airport/${quoteId}`:
                    await awaitWithLoading(airportApi.goBack(quoteId));
                    history.push(`/insurance/aircraft/${quoteId}`)
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 1, title: "aircraft", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/pilot-list/${quoteId}`:
                    await awaitWithLoading(pilotApi.goBack(quoteId));
                    history.push(`/insurance/airport/${quoteId}`)
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 1, title: "airport", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/pilots/${quoteId}`:
                    const { pilots } = state;
                    await awaitWithLoading(pilotApi.goBack(quoteId));
                    if(pilots && Object.keys(pilots).length) {
                        history.push(`/insurance/pilot-list/${quoteId}`)
                        dispatch({
                            type: QUOTE_ACTIONS.GO_BACK,
                            payload: { step: 2, title: "pilot", shouldShowPriceWidgit: false}
                        })
                    } else {
                        history.push(`/insurance/airport/${quoteId}`)
                        dispatch({
                            type: QUOTE_ACTIONS.GO_BACK,
                            payload: { step: 1, title: "airport", shouldShowPriceWidgit: false}
                        })
                    }
                    break;
                case `/insurance/coverages/${quoteId}`:
                    await awaitWithLoading(coveragesApi.goBack(quoteId));
                    history.push(`/insurance/pilot-list/${quoteId}`)
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 2, title: "pilot", shouldShowPriceWidgit: false}
                    })
                    break;
                case `/insurance/summary/${quoteId}`:
                    await awaitWithLoading(summaryApi.goBack(quoteId));
                    history.push(`/insurance/coverages/${quoteId}`)
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 3, title: "coverages", shouldShowPriceWidgit: true}
                    })
                    break;
                case `/insurance/checkout/${quoteId}`:
                    await awaitWithLoading(paymentApi.goBack(quoteId));
                    history.push(`/insurance/summary/${quoteId}`)
                    dispatch({
                        type: QUOTE_ACTIONS.GO_BACK,
                        payload: { step: 4, title: "summary", shouldShowPriceWidgit: false}
                    })
                    break;
                default:
                    break;
            }
        }
    }
}

export default QUOTE_ACTIONS;
